import React, {Component} from 'react';
import Table from 'react-bootstrap/Table'

class DeliveryPricelist extends Component {
    render() {
        return (
            <div className="container">
                <h4>Koliko to košta?</h4>
                <p>

                    Za svaki artikal se naplaćuje ista cena koju biste platili da ste lično otišli do prodavnice.
                    Dostava se dodatno naplaćuje i njena cena zavisi od oblasti gde se dostava vrši. Cene su iznete
                    u tabeli.
                </p>
                <br/>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>DESTINACIJA</th>
                        <th>CENA DNEVNE VOŽNJE</th>
                        <th>CENA NOĆNE VOŽNJE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Grad</td>
                        <td>300 din</td>
                        <td>350 din</td>
                    </tr>
                    <tr>
                        <td>Bajči Žilinskog, Carinska i Kanalska</td>
                        <td>400 din</td>
                        <td>450 din</td>
                    </tr>
                    <tr>
                        <td>Zrenjaninski put (do autoputa)</td>
                        <td>600 din</td>
                        <td>650 din</td>
                    </tr>
                    <tr>
                        <td>Šangaj, Lir</td>
                        <td>700 din</td>
                        <td>750 din</td>
                    </tr>
                    <tr>
                        <td>Privrednikova, BIG</td>
                        <td>400 din</td>
                        <td>450 din</td>
                    </tr>
                    <tr>
                        <td>Klisa I - od kanala do crkve</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Klisa II - od crkve do autoputa</td>
                        <td>500 din</td>
                        <td>550 din</td>
                    </tr>
                    <tr>
                        <td>Klisa III (Sole Mio), Rimski Šančevi</td>
                        <td>700 din</td>
                        <td>750 din</td>
                    </tr>
                    <tr>
                        <td>Avijatičarsko naselje</td>
                        <td>340 din</td>
                        <td>400 din</td>
                    </tr>
                    <tr>
                        <td>Jugovićevo II, Gradsko groblje</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Sajlovo</td>
                        <td>500 din</td>
                        <td>550 din</td>
                    </tr>
                    <tr>
                        <td>Bangladeš</td>
                        <td>600 din</td>
                        <td>650 din</td>
                    </tr>
                    <tr>
                        <td>Rumenka</td>
                        <td>850 din</td>
                        <td>900 din</td>
                    </tr>
                    <tr>
                        <td>Veternička rampa do Lipovog gaja</td>
                        <td>400 din</td>
                        <td>450 din</td>
                    </tr>
                    <tr>
                        <td>Adice I - do Sime Šolaje</td>
                        <td>400 din</td>
                        <td>450 din</td>
                    </tr>
                    <tr>
                        <td>Adice II - posle Sime Šolaje</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Ribarsko ostrvo, Kamenjar 1</td>
                        <td>400 din</td>
                        <td>450 din</td>
                    </tr>
                    <tr>
                        <td>Kamenjar 2-6</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Veternik I - od Lipovog gaja do kružnog toka</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Veternik II - posle kružnog toka</td>
                        <td>500 din</td>
                        <td>550 din</td>
                    </tr>
                    <tr>
                        <td>Futog</td>
                        <td>900 din</td>
                        <td>1000 din</td>
                    </tr>
                    <tr>
                        <td>Sremska Kamenica</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Čardak</td>
                        <td>600 din</td>
                        <td>700 din</td>
                    </tr>
                    <tr>
                        <td>Bocke</td>
                        <td>600 din</td>
                        <td>700 din</td>
                    </tr>
                    <tr>
                        <td>Paragovo</td>
                        <td>700 din</td>
                        <td>800 din</td>
                    </tr>
                    <tr>
                        <td>Popovica do Mošine vile</td>
                        <td>650 din</td>
                        <td>700 din</td>
                    </tr>
                    <tr>
                        <td>Popovica od Mošine vile</td>
                        <td>700 din</td>
                        <td>800 din</td>
                    </tr>
                    <tr>
                        <td>Petrovaradin I - do crkve</td>
                        <td>450 din</td>
                        <td>500 din</td>
                    </tr>
                    <tr>
                        <td>Petrovaradin II - posle crkve</td>
                        <td>500 din</td>
                        <td>550 din</td>
                    </tr>
                    <tr>
                        <td>Karagača, Alibegovac</td>
                        <td>600 din</td>
                        <td>700 din</td>
                    </tr>
                    <tr>
                        <td>Bukovac</td>
                        <td>800 din</td>
                        <td>900 din</td>
                    </tr>
                    <tr>
                        <td>Sremski Karlovci</td>
                        <td>1000 din</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Kać, Bački Jarak, Ledinci</td>
                        <td>1200 din</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Rakovac</td>
                        <td>1400 din</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Temerin</td>
                        <td>1500 din</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Beočin</td>
                        <td>1600 din</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Žabalj</td>
                        <td>2000 din</td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Krčedin</td>
                        <td>3000 din</td>
                        <td/>
                    </tr>
                    </tbody>
                </Table>
                <p>
                    Pod noćnom vožnjom podrazumeva se vožnja u periodu od 00h - 08h.
                </p>
                <p>
                    Na cenu naručene robe i dostave se dodaje i cena kesa u koje je porudžbina spakovana. Svaka kesa
                    se naplaćuje dva dinara. Tačan broj kesa koje će biti iskorišćene se ne može znati unapred.
                </p>
            </div>
        )
    }
}

export default DeliveryPricelist;
